<template>
  <span class="add-publication-copy" v-if="hasPerm('publications.add_publicationcopy')">
    <b-modal dialog-class="modal-lg"
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-book-reader"></i> Nouvel exemplaire</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="3">
          <b-form-group
            id="publicationType"
            description="Type"
            :disabled="publicationTypes.length <= 1"
          >
            <b-form-select
              id="publicationType"
              v-model="selectedPublicationType"
            >
              <b-form-select-option :value="itm" v-for="itm of publicationTypes" :key="itm.id">
                {{ itm.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            id="publication"
            description="Publication"
          >
            <b-form-select
              id="publication"
              v-model="selectedPublication"
              :disabled="publications.length <= 1"
            >
              <b-form-select-option :value="pub" v-for="pub of publications" :key="pub.id">
                {{ pub.shortName() }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="createdOn-group"
            description="Date"
            label-for="createdOn"
          >
            <b-form-input type="date" id="createdOn" v-model="createdOn">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="quantity-group"
            description="Quantité"
            label-for="quantity"
          >
            <b-form-input type="number" step=1 min=1 id="quantity" v-model="quantity"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="unitPrice"
            description="Prix unitaire"
            label-for="unitPrice"
            required
          >
            <decimal-input id="unitPrice" v-model="unitPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-checkbox v-model="sending" id="sending">
            Envoi
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sendingPrice"
            description="Tarif envoi"
            label-for="sendingPrice"
          >
            <decimal-input :disabled="!sending" id="sendingPrice" v-model="sendingPrice"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="discount"
            description="Réduction"
            label-for="discount"
            required
          >
            <decimal-input id="discount" v-model="discount"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="totalPrice"
            description="Prix total"
            label-for="totalPrice"
            required
          >
            <decimal-input disabled id="totalPrice" v-model="totalPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!deposit && !entity">
        <b-col>
          <b-checkbox v-model="hasEntity" id="hasEntity">
            Saisir le client
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="entity || deposit || hasEntity">
        <b-col cols="9">
          <entity-typeahead
            @change="onEntityChanged"
            @init="onEntityChanged"
            :initial-id="deposit ?  deposit.entity.id : (entity ?  entity.id : 0)"
            :disabled="(deposit !== null) || (entity !== null)"
          >
          </entity-typeahead>
        </b-col>
        <b-col>
          <b-input type="text" disabled :value="deposit ? 'Dépôt N°' + deposit.depositNumber : ''">
          </b-input>
        </b-col>
      </b-row>
      <b-row v-if="totalPrice">
        <b-col>
          <b-checkbox v-model="autoInvoice" id="autoInvoice">
            Facturer automatiquement la vente
          </b-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import EntityTypeahead from '@/components/Entity/EntityTypeahead.vue'
import { makePublication, makePublicationCopy } from '@/types/publications'
import { BackendApi } from '@/utils/http'
import { distinct } from '@/utils/arrays'

export default {
  name: 'add-publication-copy-modal',
  components: { EntityTypeahead, DecimalInput, },
  mixins: [BackendMixin],
  props: {
    publication: {
      type: Object,
      default: null,
    },
    modalId: {
      type: String,
      default: 'bv-add-publication-copy',
    },
    deposit: {
      type: Object,
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      createdOn: null,
      totalPrice: 0,
      unitPrice: 0,
      discount: 0,
      sending: 0,
      sendingPrice: 0,
      errorText: '',
      quantity: 1,
      hasEntity: false,
      selectedEntity: null,
      sentOn: null,
      autoInvoice: false,
      selectedPublicationType: null,
      selectedPublication: null,
      publicationTypes: [],
      allPublications: [],
    }
  },
  computed: {
    publications() {
      if (this.selectedPublicationType) {
        return this.allPublications.filter(
          elt => elt.publicationType.id === this.selectedPublicationType.id
        )
      }
      return []
    },
  },
  watch: {
    unitPrice: function() { this.updatePrice() },
    quantity: function() { this.updatePrice() },
    sendingPrice: function() { this.updatePrice() },
    sending: function() { this.updatePrice() },
    discount: function() { this.updatePrice() },
    publication: function() {},
    selectedPublication: function() {
      if (this.selectedPublication) {
        this.sendingPrice = this.selectedPublication.sendingPrice
        this.unitPrice = this.selectedPublication.price
      } else {
        this.sendingPrice = 0
        this.unitPrice = 0
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      if (this.publication) {
        this.allPublications = [this.publication]
        this.publicationTypes = [this.publication.publicationType]
        this.selectedPublication = this.allPublications[0]
        this.selectedPublicationType = this.publicationTypes[0]
      } else {
        await this.loadAllPublications()
      }
    },
    isDisabled() {
      return (
        (this.createdOn === null) ||
        (this.quantity <= 0) ||
        (this.selectedPublication === null) ||
        (this.hasEntity && !this.selectedEntity)
      )
    },
    async loadAllPublications() {
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publications/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.allPublications = resp.data.map(makePublication)
        this.publicationTypes = distinct(this.allPublications.map(elt => elt.publicationType))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async onSave() {
      this.errorText = ''
      try {
        const url = '/publications/api/add-publication-copy/'
        const backendApi = new BackendApi('post', url)
        let entity = null
        if (this.entity) {
          entity = this.entity
        } else if (this.deposit) {
          entity = this.deposit.entity
        } else if (this.hasEntity) {
          entity = this.selectedEntity
        }
        let data = {
          publication: this.selectedPublication.id,
          entity: entity ? entity.id : null,
          unit_price: this.unitPrice,
          sending: this.sending,
          sending_price: this.sending ? this.sendingPrice : 0,
          discount: this.discount,
          quantity: this.quantity,
          total_price: this.totalPrice,
          created_on: moment(this.createdOn).format('YYYY-MM-DD'),
          sent_on: this.sentOn ? moment(this.sentOn).format('YYYY-MM-DD') : null,
          deposit: this.deposit ? this.deposit.id : 0,
          auto_invoice: this.autoInvoice,
        }
        const resp = await backendApi.callApi(data)
        this.$bvModal.hide(this.modalId)
        this.$emit('done', makePublicationCopy(resp.data))
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    updatePrice() {
      let sendingPrice = 0
      if (this.sending) {
        sendingPrice = +this.sendingPrice
      }
      this.totalPrice = (+this.quantity) * (+this.unitPrice) + sendingPrice - (+this.discount)
    },
    onEntityChanged(event) {
      if (event.entity) {
        this.selectedEntity = event.entity
      } else {
        this.selectedEntity = null
      }
    },
  },
  mounted() {
    this.createdOn = moment().format('YYYY-MM-DD')
  },
}
</script>
<style lang="less">
</style>
