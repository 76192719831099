<template>
  <span class="add-publication-modal" v-if="hasPerm('publications.add_publicationdeposit')">
    <b-modal dialog-class="modal-lg"
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-book-reader"></i> Nouveau dépôt</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="depositOn-group"
            description="Date"
            label-for="depositOn"
          >
            <b-form-input type="date" id="depositOn" v-model="depositOn">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <entity-typeahead
            @change="onEntityChanged"
            @init="onEntityChanged"
          >
          </entity-typeahead>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import EntityTypeahead from '@/components/Entity/EntityTypeahead.vue'
import { makePublicationDeposit } from '@/types/publications'
import { BackendApi } from '@/utils/http'

export default {
  name: 'add-publication-deposit-modal',
  components: { EntityTypeahead, },
  mixins: [BackendMixin],
  props: {
    publication: Object,
    modalId: {
      type: String,
      default: 'bv-add-publication-deposit',
    },
  },
  data() {
    return {
      depositOn: null,
      entity: null,
      errorText: '',
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isDisabled() {
      return (
        (this.depositOn === null) ||
        (!this.entity)
      )
    },
    async onSave() {
      this.errorText = ''
      try {
        const url = '/publications/api/add-publication-deposit/'
        const backendApi = new BackendApi('post', url)
        let data = {
          entity: this.entity.id,
          deposit_on: moment(this.depositOn).format('YYYY-MM-DD'),
        }
        const resp = await backendApi.callApi(data)
        this.$bvModal.hide(this.modalId)
        this.$emit('done', makePublicationDeposit(resp.data))
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    onEntityChanged(event) {
      if (event.entity) {
        this.entity = event.entity
      } else {
        this.entity = null
      }
    },
  },
  mounted() {
    this.depositOn = moment().format('YYYY-MM-DD')
  },
}
</script>
<style lang="less">
</style>
